<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
            <small class="primary-color">Note:&nbsp; The asset will be getting uploaded in the background and you can only train the bot using the asset once it is available down on the dashboard</small>
          </template>
          <template v-slot:headerAction>
            <b-button class="mr-4" variant="primary" @click="chatbot_data_srcAdd">{{cvbtnUploadFile}}</b-button>
            <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button mr-2"  />
            <CsvUpload  :propModulePrefix="cvModulePrefix" class="btn Download_button" />
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchchatbot_data_srcWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col sm="8" md="8" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getchatbot_data_srcDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="chatbot_data_srcObjList && chatbot_data_srcObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="chatbot_data_srcObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button title="Train asset" variant=" iq-bg-success mr-1 mb-1" size="sm" @click="trainBot(data.item)" v-if="!data.item.editable"><i class="ri-git-repository-commits-fill"></i></b-button>
                    <b-button title="Delete asset" variant=" iq-bg-danger" @click="showChatBotDataSrcDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelChatBotDataSrcAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <ChatBotDataSrcAdd :propOpenedInModal="true" @emitCloseChatBotDataSrcAddModal="closeChatBotDataSrcAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="chatbot_data_srcAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeChatBotDataSrcAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelChatBotDataSrcEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <ChatBotDataSrcEdit :propOpenedInModal="true" :propChatBotDataSrcObj="chatbot_data_srcEditObj" @emitCloseChatBotDataSrcEditModal="closeChatBotDataSrcEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="chatbot_data_srcEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeChatBotDataSrcEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelChatBotDataSrcDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelChatBotDataSrcDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="chatbot_data_srcDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { ChatBotDataSrcs } from "../../../FackApi/api/ChatBotDataSrc.js"
import ChatBotDataSrcAdd from "./ChatBotDataSrcAdd.vue"
import ChatBotDataSrcEdit from "./ChatBotDataSrcEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "ChatBotDataSrcList",
  components: {
    ChatBotDataSrcAdd,
    ChatBotDataSrcEdit,
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      cvCardTitle: "ChatBot Data Source",
      cvbtnUploadFile: "Upload Asset",
      cvbtnBulkUploadWebURL: "Web URL CSV",
      cvbtnWebURL: "Web URL",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit ChatBotDataSrc",
      cvAddModalHeader: "Upload File",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "ChatBotDataSrc",
      showModelChatBotDataSrcAdd: false,
      showModelChatBotDataSrcEdit: false,
      showModelChatBotDataSrcDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "file type", key: "file_type", class: "text-left align-text-top", sortable: true },
        { label: "file name", key: "file_name", class: "text-left align-text-top", sortable: true },
        { label: "file extension", key: "file_extension", class: "text-left align-text-top", sortable: true },
        { label: "data schema", key: "data_schema", class: "text-left align-text-top", sortable: true },
        { label: "url", key: "url", class: "text-left align-text-top", sortable: true },
        { label: "replace keywords", key: "replace_keywords", class: "text-left align-text-top", sortable: true },
        { label: "training file path", key: "training_file_path", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      chatbot_data_srcObjList: [],
      chatbot_data_srcEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true,
      cvModulePrefix: "weburl",
      cvModuleFile: "web_url"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    await this.getDateFilter()
    socialvue.index()
    this.chatbot_data_srcList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchchatbot_data_srcWithParams
     */
    async searchchatbot_data_srcWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.chatbot_data_srcList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.chatbot_data_srcList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getchatbot_data_srcDateWise () {
      this.chatbot_data_srcList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * chatbot_data_srcList
     */
    async chatbot_data_srcList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let chatbotDataSrcListResp = await ChatBotDataSrcs.chatbot_data_srcList(this, this.whereFilter)
        if (chatbotDataSrcListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.chatbot_data_srcObjList = [ ...chatbotDataSrcListResp.resp_data.data ]
          }
          else {
            this.chatbot_data_srcObjList = [ ...this.chatbot_data_srcObjList, ...chatbotDataSrcListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.chatbot_data_srcObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.chatbot_data_srcObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = chatbotDataSrcListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcList() and Exception:", err)
      }
    },
    /**
     * chatbot_data_srcAdd
     */
    chatbot_data_srcAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/chatbot_data_src_add")
        }
        else {
          this.showModelChatBotDataSrcAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcAdd() and Exception:", err.message)
      }
    },
    /**
     * chatbot_data_srcEdit
     */
    chatbot_data_srcEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/chatbot_data_src_edit/" + this.chatbot_data_srcEditObj.cbds_id)
        }
        else {
          this.chatbot_data_srcEditObj = item
          this.showModelChatBotDataSrcEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcEdit() and Exception:", err.message)
      }
    },
    /**
     * showChatBotDataSrcDeleteDialog
     */
    showChatBotDataSrcDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelChatBotDataSrcDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showChatBotDataSrcDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * chatbot_data_srcDelete
     */
    async chatbot_data_srcDelete () {
      try {
        let chatbotDataSrcrcDelResp = await ChatBotDataSrcs.chatbot_data_srcDelete(this, this.delObj.cbds_id)
        await ApiResponse.responseMessageDisplay(this, chatbotDataSrcrcDelResp)

        if (chatbotDataSrcrcDelResp && !chatbotDataSrcrcDelResp) {
          this.showModelChatBotDataSrcDelete = false
          return false
        }

        let index = this.chatbot_data_srcObjList.indexOf(this.delObj)
        this.chatbot_data_srcObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelChatBotDataSrcDelete = false
      }
      catch (err) {
        console.error("Exception occurred at chatbot_data_srcDelete() and Exception:", err.message)
      }
    },
    /**
     * closeChatBotDataSrcAddModal
     */
    closeChatBotDataSrcAddModal (chatbotDataSrcAddData) {
      try {
        if (chatbotDataSrcAddData) {
          if (this.chatbot_data_srcObjList && this.chatbot_data_srcObjList.length >= 1) {
            let chatbotDataSrcObjLength = this.chatbot_data_srcObjList.length
            let lastId = this.chatbot_data_srcObjList[chatbotDataSrcObjLength - 1]["id"]
            chatbotDataSrcAddData.id = lastId + 1
          }
          else {
            this.chatbot_data_srcObjList = []
            chatbotDataSrcAddData.id = 11111
          }

          chatbotDataSrcAddData.created_on = moment()
          this.chatbot_data_srcObjList.unshift(chatbotDataSrcAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelChatBotDataSrcAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closechatbot_data_srcAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeChatBotDataSrcEditModal
     */
    closeChatBotDataSrcEditModal () {
      try {
        this.showModelChatBotDataSrcEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeChatBotDataSrcEditModal() and Exception:", err.message)
      }
    },
    /**
     * trainBot
     */
    async trainBot (dataSrcObj) {
      try {
        const trainResp = await ChatBotDataSrcs.chatbot_data_trainBot(this, dataSrcObj)
        await ApiResponse.responseMessageDisplay(this, trainResp)
      }
      catch (err) {
        console.error("Exception in trainBot and err: ", err)
      }
    }
  }
}
</script>
